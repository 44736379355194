import React from "react";
import ViewSection from "../components/ViewSection/ViewSection";
import styled from 'styled-components'
import Portrait from '../assets/portrait.jpg';


const Content = styled.div`
	position: relative;
	overflow: hidden;
	margin: 5% auto;
	padding: 0;
	background: white;
	color: black;
	display: flex;
	flex-direction: column;
	@media (min-width: 640px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1200px) {
		flex-direction: row;
		width: 1150px;
    }
    @media (min-width: 1600px) {
		width: 1440px;
    }
`;
const LeftContent = styled.div`
	padding: 10% 7.5%;
	width: 100%;
	display: flex;
    flex-direction: column;
	justify-content: space-between;
	background: rgb(45,45,45);
	@media (min-width: 640px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1200px) {
		width: 50%;
    }
    @media (min-width: 1600px) {
    }
`;

const RightContent = styled.div`
	position: relative;
	padding: 10% 0 10% 7.5%;
	overflow: hidden;
	width: 100%;
	z-index: 20;
	@media (min-width: 640px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1200px) {
		width: 50%;
    }
    @media (min-width: 1600px) {
    }
`;

const ContentBox = styled.div`
	font-weight: 200;
	color: #222;
	margin-top: 5%; 
	margin-left: 3%;
`;

const ContentBoxHeadline = styled.h1`
	font-size: 1rem;
	font-weight: 500;
	font-weight: 600;
	margin: 0 0 0.5rem 0;
`;

const ContentBoxText = styled.div`
`;
const ContentLabel = styled.div`
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	font-size: 12rem;
	position: absolute;
	right: 0;
	bottom: -4rem;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
	color: #EEE;
	z-index: 25;
	color: rgba(30, 30, 30, 0.5);
	/*filter: invert(100%);*/
	mix-blend-mode: difference;
`;
const PortraitImage = styled.div`
	background: url(${Portrait});
	height: 125px;
    width: 125px;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
`;

const CVSection = (props) => {

	return (
		<ViewSection style={{minHeight: '100vh'}}>
			<Content>
				<ContentLabel>Resumé</ContentLabel>
				<LeftContent>
					<ContentBox style={{zIndex: 1, color: 'white', marginTop: 0}}>
						<PortraitImage />
					</ContentBox>
					<ContentBox style={{zIndex: 1, color: 'white'}}>
						<ContentBoxHeadline>About</ContentBoxHeadline>
						<ContentBoxText style={{lineHeight: 'initial'}}>
							Hi, I'm Dennis! Born 1978, living and working in Germany. In the past years I worked for creative agencies across Germany as technical director and technical lead.
						</ContentBoxText>
					</ContentBox>
					<ContentBox style={{zIndex: 1, color: 'white'}}>
						<ContentBoxHeadline>Passion</ContentBoxHeadline>
						<ContentBoxText style={{lineHeight: 'initial'}}>
							My passion is to build great technical platforms, apps and web pages. I'm always looking for new technologies and innovations that help to build better solutions.
						</ContentBoxText>
					</ContentBox>
				</LeftContent>
				<RightContent>
					<div style={{fontSize: '1.2rem', fontWeight: '100'}}>
						Experience from the past 5 years
					</div>
					<ContentBox>
						<ContentBoxHeadline style={{fontWeight: 600, margin: '1rem 0 0 0'}}>antoni Garage</ContentBoxHeadline>
						<ContentBoxText>
							Technical Director<br />
							<span style={{fontStyle: 'italic'}}>Mercedes-Benz</span>
						</ContentBoxText>
					</ContentBox>
					<ContentBox>
						<ContentBoxHeadline style={{fontWeight: 600, margin: '1rem 0 0 0'}}>Experience One / Nolte & Lauth</ContentBoxHeadline>
						<ContentBoxText>
							Technical Project Manager (freelance)<br />
							<span style={{fontStyle: 'italic'}}>Mercedes-Benz Vans</span>
						</ContentBoxText>
					</ContentBox>
					<ContentBox>
						<ContentBoxHeadline style={{fontWeight: 600, margin: '1rem 0 0 0'}}>Saatchi pro / zweimaleins</ContentBoxHeadline>
						<ContentBoxText>
							Technical Director (interim, freelance)<br />
							<span style={{fontStyle: 'italic'}}>Telekom, ORLEN/Star, Commerzbank</span>
						</ContentBoxText>
					</ContentBox>
					<ContentBox>
						<ContentBoxHeadline style={{fontWeight: 600, margin: '1rem 0 0 0'}}>Saatchi & Saatchi</ContentBoxHeadline>
						<ContentBoxText>
							Technical Project Manager (freelance)<br />
							<span style={{fontStyle: 'italic'}}>Toyota, Lexus</span>
						</ContentBoxText>
					</ContentBox>
					<ContentBox>
						<ContentBoxHeadline style={{fontWeight: 600, margin: '1rem 0 0 0'}}>TLGG</ContentBoxHeadline>
						<ContentBoxText>
							Technical Project Manager &<br />Backend Developer (freelance)<br />
							<span style={{fontStyle: 'italic'}}>WIRED Germany, Carlsberg, Montblanc</span>
						</ContentBoxText>
					</ContentBox>
				</RightContent>
			</Content>
		</ViewSection>
	);
};

export default CVSection;