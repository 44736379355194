import React from 'react';
import { SectionContainer, SectionContent } from './ViewSection.styled';

function ViewSection(props) {
  return (
    <SectionContainer style={{ minHeight: '100vh' }}>
      <SectionContent>
        {props.children}
      </SectionContent>
    </SectionContainer>
  );
};

export default ViewSection;
