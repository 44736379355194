import styled, {keyframes} from "styled-components";

const dotAnimation = keyframes`
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(20px);
        opacity: 0;
    }
`;

export const ScrollAnimationContainer = styled('div')`
    border: 1px solid #FFF;
    height: 35px;
    width: 22px;
    border-radius: 12px;
    background: rgba(49,53,58,0.6);
    opacity: 0;
    translate: opacity 1s;
    &.show {
        opacity: 1;
    }
`;

export const ScrollAnimationDot = styled('div')`
    border: 2px solid #FFF;
    border-radius: 2px;
    height: 7px;
    width: 3px;
    top: 5px;
    position: relative;
    margin: 0 auto;
    display: ${props => props.hidden ? 'none' : 'block'};
    animation: ${dotAnimation} 2s infinite;
`;
