import React from "react";
import './App.css';
import styled from 'styled-components'
import CVSection from './sections/CVSection';
import FullBioSection from "./sections/FullBioSection";
import IntroSection from "./sections/IntroSection";

export const PageContainer = styled.div`
  height: 100vh;
  height: fill-available;
`;

const App = () => {

  return (
    <PageContainer>
      <IntroSection />
      <CVSection />
      <FullBioSection />
    </PageContainer>
  );
}

export default App;