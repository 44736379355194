import React, { useEffect, useState } from "react";
import styled, { keyframes } from 'styled-components';
import {ScrollAnimationContainer, ScrollAnimationDot} from "../components/ScrollAnimation/ScrollAnimation.styled";

const LineInAnimation = keyframes`
    0% { 
        width: 0;
    } 
    100% { 
        width: 100%;
	} 
`
const LineOutAnimation = keyframes`
    0% { 
		width: 100%;
		right: 0;
		left: initial;
    } 
    100% { 
		right: 0;
		left: initial;
        width: 0;
	} 
`
const Container = styled.div`
	height: 100vh;
	position: relative;
	padding: 10%;
	display: flex;
	margin: 0 auto;
	flex-flow: column;
	justify-content: center;
	@media (min-width: 640px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1200px) {
		padding: 2%;
		width: 1150px;
    }
    @media (min-width: 1600px) {
		width: 1440px;
    }
`
const AnimationContainer = styled.div`
	position: relative;
	margin: 0;
	padding: 0;
	color: white;
	transition: max-width 300ms, max-height 300ms;
	display: flex;
	/*font-family: 'Neuton', serif;*/
	font-weight: 200;
    align-items: center;
	&.loading {
		max-width: 100%;
	}
	&.done {
		max-width: 100%;
		max-height: 100px;
	}
`
const Letter = styled.div`
	font-size: 2.5rem;
	letter-spacing: .2rem;
	transform: translateY(5px);
	transition: transform 400ms ease-out, opacity 300ms ease-out;
	opacity: 0;
	.animate & {
		transform: translateY(0);
		opacity: 1;
	}
`
const AnimatedLine = styled.div`
	background: white;
	width: 0;
	left: 0;
	height: 3px;
	border-radius: 1px;
	position: absolute;
	animation: .6s ${LineInAnimation} ease-in forwards, .6s ${LineOutAnimation} .7s ease-in forwards;
	will-change: width;
`
const Description = styled.div`
	text-transform: uppercase;
	line-height: initial;
	color: white;
	margin: 15px 0 0 15px;
	max-width: 0;
	max-height: 100%;
	overflow: hidden;
	transition: max-width 1.9s, border-left .5s;
	transition-delay: 1.3s;
	.animateHeight & {
		max-height: 100%;
	}
	.animateWidth & {
		border-left: 1px solid white;
		max-width: 100%;
	}
`
const IntroSection = (props) => {

	const [animationClass, setAnimationClass] = useState(true);
	const [showDot, showAnimatedDot] = useState(false);

	useEffect(() => {
		const nameTimer = setTimeout(() => {
			setAnimationClass('animate animateHeight animateWidth');
		}, 800);
		const dotTimer = setTimeout(() => {
			showAnimatedDot(true);
		}, 3000);
		return () => {
			clearTimeout(nameTimer);
			clearTimeout(dotTimer);
		}
	}, []);

	const delay = 0.08;
	return (
		<Container className={animationClass}>
			<AnimationContainer>
				<Letter style={{transitionDelay: (0 * delay) + 's'}}>D</Letter>
				<Letter style={{transitionDelay: (1 * delay) + 's'}}>e</Letter>
				<Letter style={{transitionDelay: (2 * delay) + 's'}}>n</Letter>
				<Letter style={{transitionDelay: (3 * delay) + 's'}}>n</Letter>
				<Letter style={{transitionDelay: (4 * delay) + 's'}}>i</Letter>
				<Letter style={{transitionDelay: (5 * delay) + 's'}}>s</Letter>
				<Letter style={{transitionDelay: (6 * delay) + 's'}}>&nbsp;</Letter>
				<Letter style={{transitionDelay: (7 * delay) + 's'}}>H</Letter>
				<Letter style={{transitionDelay: (8 * delay) + 's'}}>a</Letter>
				<Letter style={{transitionDelay: (9 * delay) + 's'}}>n</Letter>
				<Letter style={{transitionDelay: (10 * delay) + 's'}}>t</Letter>
				<Letter style={{transitionDelay: (11 * delay) + 's'}}>k</Letter>
				<Letter style={{transitionDelay: (12 * delay) + 's'}}>e</Letter>
			<AnimatedLine />
			</AnimationContainer>
			<Description>
				<div style={{marginLeft: '8px', minWidth: '250px'}}>Technical Director</div>
				<div style={{marginLeft: '8px', minWidth: '250px'}}>Creative Technologist</div>
				<div style={{marginLeft: '8px', minWidth: '250px'}}>Developer, Architect, Nerd</div>
			</Description>
			<div style={{position: 'absolute', bottom: '5%', display: 'flex', left: 0, right: 0, justifyContent: 'center'}}>
				<ScrollAnimationContainer className={showDot ? 'show' : ''} >
					<ScrollAnimationDot/>
				</ScrollAnimationContainer>
			</div>
		</Container>
	);
};

export default IntroSection;