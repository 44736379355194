import React, { useState } from "react";
import ViewSection from "../components/ViewSection/ViewSection";
import styled from 'styled-components'
import ArrowLeft from '../assets/Arrow_Left.svg';
import ArrowRight from '../assets/Arrow_Right.svg';

const Content = styled.div`
	position: relative;
	overflow: hidden;
	margin: 5% auto;
	padding: 0;
	background: white;
	color: black;
	display: flex;
	flex-direction: column;
	@media (min-width: 640px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1200px) {
		flex-direction: row;
		width: 1150px;
    }
    @media (min-width: 1600px) {
		width: 1440px;
    }
`;
const LeftContent = styled.div`
	padding: 10% 7.5%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: rgb(45,45,45);
	@media (min-width: 640px) {
	}
	@media (min-width: 768px) {
	}
	@media (min-width: 1024px) {
	}
	@media (min-width: 1200px) {
		width: 50%;
	}
	@media (min-width: 1600px) {
	}
`;
const RightContent = styled.div`
	position: relative;
	padding: 10% 0 10% 7.5%;
	overflow: hidden;
	width: 100%;
	z-index: 20;
	@media (min-width: 640px) {
	}
	@media (min-width: 768px) {
	}
	@media (min-width: 1024px) {
	}
	@media (min-width: 1200px) {
		width: 50%;
	}
	@media (min-width: 1600px) {
	}
`;

const ContentBox = styled.div`
	/*font-family: 'Playfair Display', serif;*/
	font-weight: 200;
	color: #222;
	margin-top: 5%; 
	margin-left: 3%;
`;

const ContentBoxHeadline = styled.h1`
	font-size: 1rem;
	font-weight: 500;
	font-weight: 600;
	margin: 0 0 0.5rem 0;
`;

const ContentBoxText = styled.div`
`;
const ContentBoxDate = styled.div`
	margin: 1rem 0 0 0;
    font-style: italic;
    text-decoration: underline;
`;
const ContentLabel = styled.div`
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	font-size: 12rem;
	position: absolute;
	right: 0;
	bottom: -4rem;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
	z-index: 1;
	color: rgba(30, 30, 30, 0.5);
	/*filter: invert(100%);*/
	mix-blend-mode: difference;
`;
const SwipePanel = styled.div`
	width: 375px
`;
const SwipeContent = styled.div`
	width: 2500px;
	display: flex;
	transition: transform 300ms;
`;
const ScrollIndicatorContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 30;
`;

const ScrollIndicator = styled.div`
	background: #DDD;
	width: 15%;
	height: 12px;
	cursor: pointer;
	border-top: 4px solid white;
	border-bottom: 4px solid white;
	margin: 0 4px;
	&.active {
		background: #555;
		cursor: initial;
	}
`;

const RightNavigation = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100px;
	height: 100%;
	z-index: 1;
	&.active {
		cursor: url(${ArrowLeft}), auto;
	}
`;
const LeftNavigation = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100px;
	height: 100%;
	z-index: 1;
	&.active {
		cursor: url(${ArrowRight}), auto;
	}
`;

const FullBioSection = (props) => {

	const [activePage, setActivePage] = useState(0);

	return (
		<ViewSection>
			<Content>
				<ContentLabel>Full Bio</ContentLabel>
				<LeftContent>
					<ContentBox style={{ zIndex: 1, color: 'white' }}>
						<ContentBoxHeadline>Skills</ContentBoxHeadline>
						<ContentBoxText>
							techn. concepts, infrastructure and software architecture, programming and prototyping (node/react./Angular) PHP, Java), team lead and supervising dev teams
						</ContentBoxText>
					</ContentBox>
					<ContentBox style={{ zIndex: 1, color: 'white' }}>
						<ContentBoxHeadline>Clients</ContentBoxHeadline>
						<ContentBoxText>
							antoni Garage, Condé Nast Germany, Experience One / Nolte & Lauth, Saatchi & Saatchi, Saatchi pro, TLGG, webxells, zweimaleins
						</ContentBoxText>
					</ContentBox>
					<ContentBox style={{ zIndex: 1, color: 'white' }}>
						<ContentBoxHeadline>Brands</ContentBoxHeadline>
						<ContentBoxText>
							Arvato, Audi, Carlsberg, Commerzbank, Lexus, ORLEN/Star, McDonald's, Mercedes-Benz, Mercedes-Benz Vans, Montblanc, Seat, SPD, Telekom, Toyota
						</ContentBoxText>
					</ContentBox>
				</LeftContent>

				<RightContent>
					<RightNavigation className={activePage !== 0 ? 'active' : ''} onClick={() => activePage > 0 ? setActivePage(activePage - 1) : null} />
					<LeftNavigation className={activePage !== 4 ? 'active' : ''} onClick={() => activePage < 4 ? setActivePage(activePage + 1) : null} />
					<SwipeContent style={{ transform: 'translateX(-' + (activePage * 340) + 'px' }}>

						<SwipePanel>
							<ContentBox>
								<ContentBoxDate>04/2020 - today</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Technische Hochschule Brandenburg</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Lecturer informatics</div>
								<ContentBoxText>
									As lecturer in informatics, I teach bachelor and master students in programming, databases and hardware/software development.
								</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>08/2019 - 01/2020</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Experience One / Nolte & Lauth</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Project Manager, freelance</div>
								<ContentBoxText>
									For EO, I supervised the developer and supported the migration of the Mercedes-Benz Vans Product Advisor to the new MB #OneWeb platform.
								</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>11/2018 - 01/2019</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Experience One / Nolte & Lauth</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Angular Deveoper, freelance</div>
								<ContentBoxText>
									At Experience One I worked as Anguluar developer an implemented new features for the Mercedes-Benz Vans Product Advisor.
								</ContentBoxText>
							</ContentBox>
						</SwipePanel>

						<SwipePanel>
							<ContentBox>
								<ContentBoxDate>04/2017 - 09/2017</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Saatchi & Saatchi</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Project Manager, freelance</div>
								<ContentBoxText>
									For Saatchi & Saatchi I worked for Lexus Germany. The main task was to lead the migration of the online forms to a newer infrastructure. 
								</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>01/2017 - 04/2018</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>antoni Garage</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Director</div>
								<ContentBoxText>
									As TD, I mainly worked for Mercedes-Benz online campaigns and supervised and managed developers and advised the creative teams.
								</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>06/2016 - 12/2016</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>antoni Garage</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Director (interim), freelance</div>
								<ContentBoxText>
									For antoni I started as interim technical director for online campaigns like Mercedes-Benz #growup, C-Class cabriolet and x-mas webspecial.
								</ContentBoxText>
							</ContentBox>
						</SwipePanel>
						<SwipePanel>
							<ContentBox>
								<ContentBoxDate>05/2015 - 12/2016</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Saatchi & Saatchi</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Project Manager, freelance</div>
								<ContentBoxText>
									For the Toyota online forms relaunch I concepted the new infrastructure, the technical architecture and supervised the developer.
								</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>10/2014 - 08/2015</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Saatchi pro / zweimaleins</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Director (interim), freelance</div>
								<ContentBoxText>
									At Saatchi & Saatchi I coordinated and managed the tech. and developer team for clients like Telekom, Toyota, ORLEN/Star and Commerzbank.
						</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>12/2014 - today</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Bytescreen Media</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Founder and CTO</div>
								<ContentBoxText>
									Bytescreen Media is a software development agency that I started. In the past few years we have developed web solutions and mobile apps for creative agencies across Germany.
								</ContentBoxText>
							</ContentBox>
						</SwipePanel>
						<SwipePanel>
							<ContentBox>
								<ContentBoxDate>10/2014 - 08/2015</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>TLGG</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Project Manager &<br />Backend Developer</div>
								<ContentBoxText>
									The tech/lifestyle magazine #Wired launched their new news platform in Germany. For this project I worked as techn. architect, techn. project manager and PHP backend developer.
						</ContentBoxText>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>08/2012 - 05/2015</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>D64/Network Media</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Project Manager &<br />Technical Concept, freelance</div>
								<ContentBoxText>
									At D64/NWMD I worked for the SPD online platform mitmachen.de and campa.netz. I mainly worked on the technical concept and managed and supported the development partner.
								</ContentBoxText>
							</ContentBox>
						</SwipePanel>
						<SwipePanel style={{width: '350px'}}>
							<ContentBox>
								<ContentBoxDate>07/2012 - today</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>Dennis Hantke, freelance</ContentBoxHeadline>
							</ContentBox>
							<ContentBox>
								<ContentBoxDate>08/2003 - 06/2012</ContentBoxDate>
								<ContentBoxHeadline style={{marginBottom: 0}}>commsult AG</ContentBoxHeadline>
								<div style={{marginBottom: '.5rem'}}>Technical Project Manager &<br />Java Developer</div>
								<ContentBoxText>
									At commsult AG I worked as technical project manager and Java developer for brands like Allianz, Audi, McDonald's, Seat. The focus was the techn. Concept and programming of online campaigns and mobile apps like McDonald’s #MeinBurger, McDonald’s Intranet, Audi AR-Calendar, Audi e-tron webspecial, Seat Rock am Ring, McDonald’s App.
						</ContentBoxText>
							</ContentBox>
						</SwipePanel>
					</SwipeContent>
					<ScrollIndicatorContainer>
						<ScrollIndicator className={activePage === 0 ? 'active' : ''} onClick={() => setActivePage(0)} />
						<ScrollIndicator className={activePage === 1 ? 'active' : ''} onClick={() => setActivePage(1)} />
						<ScrollIndicator className={activePage === 2 ? 'active' : ''} onClick={() => setActivePage(2)} />
						<ScrollIndicator className={activePage === 3 ? 'active' : ''} onClick={() => setActivePage(3)} />
						<ScrollIndicator className={activePage === 4 ? 'active' : ''} onClick={() => setActivePage(4)} />
					</ScrollIndicatorContainer>
				</RightContent>
			</Content>
		</ViewSection>
	);
};

export default FullBioSection;