import styled from 'styled-components'

export const SectionContainer = styled.div`
    margin: 0;
    padding: 0;
    max-width: 100vw;
`;

export const SectionBackground = styled.div`
    background: ${props => props.background};
    background-size: cover;
    background-position: center;
    position: sticky;
    height: 100%;
    top: 0;
    z-index: -1;
`;

export const SectionContent = styled.div`
    /* margin: -100% auto 0 auto; */
    padding: 0;
    color: white;
    /* width: 1000px; */
`;
